<template>
    <section class="recruitPage" data-subTopAni>
        <div class="topArea" data-aniDelay-basic="5">
            <h2 class="contentSize-padding"><span data-textAni>발전자원 모집</span></h2>
            <div>
                <strong data-textAni>
                    발전사업자를 위한<br>
                    수익 최적화 솔루션
                </strong>
            </div>
            <div>
                <p data-textAni>
                    <b>업계 최고수준 조건</b>으로<br>
                     발전사업자의<br class="mobile"> 수익 최적화를 돕습니다.
                </p>
            </div>
        </div>

        <div data-scrollPosition="50">
            <div class="productArea contentSize-padding">
                <strong class="subTitle" data-textAni>주요 상품 소개</strong>
                <ul data-animate-list="bottomToTop" data-aniDelay="4">
                    <li>
                        <strong data-textAni data-aniDelay="7">RE100 장기고정계약</strong>
                        <p data-textAni data-aniDelay="9">
                            RPS 장기고정계약 대비<br>
                            경쟁력 있는 계약 조건
                        </p>
                    </li>
                    <li>
                        <strong data-textAni data-aniDelay="9">발전소 매입</strong>
                        <p data-textAni data-aniDelay="11">
                            토지부터 운영 중인 발전소까지<br>
                            다양한 형태의 자산 매입
                        </p>
                    </li>
                </ul>
            </div>
        </div>

        <div data-scrollPosition="50">
            <div class="content-process contentSize-padding">
                <p class="subTitle" data-textAni>
                    장기고정계약도, 발전소 매입도<br>
                    <b>간소화된 계약 절차로</b>
                </p>
                <b class="case01" data-textAni data-aniDelay="4">
                    수익최적화 솔루션 프로세스
                </b>
                <ul data-animate-list="bottomToTop" data-aniDelay="6">
                    <li>
                        <strong>문의 접수</strong>
                    </li>
                    <li>
                        <strong>검토 자료 제출</strong>
                    </li>
                    <li>
                        <strong>사업성 검토</strong>
                    </li>
                    <li>
                        <strong>사업 제안</strong>
                    </li>
                    <li>
                        <strong>
                            RE100<br>
                            장기고정계약
                            <span></span>
                            매입 계약
                        </strong>
                    </li>
                </ul>
            </div>
        </div>

        <div class="content-img contentSize-padding">
            <strong class="subTitle" data-textAni data-scrollPosition="70">RE100 장기고정계약</strong>
            <p class="subTitle2" data-textAni data-scrollPosition="70">
                <b>RE100 이행을 원하는 기업</b>과<br class="mobile"> 장기고정계약으로<br>
                안정적인 수익을 창출하세요.
            </p>
            <div class="box-img" data-animate="bottomToTop" data-scrollPosition="70">RE100 장기고정계약 이미지</div>
           <!--  <dl data-animate-list="bottomToTop" data-scrollPosition="70">
                <dt>*직접PPA</dt>
                <dd>
                    재생에너지를 이용하여 생산된 전기를 전력시장을 통하지 않고<br>
                    재생에너지전기공급사업자를 통해 전기사용자에게 직접 공급하는 제도
                </dd>
            </dl> -->
        </div>
        
        <div data-scrollPosition="50">
            <div class="condition01Area contentSize-padding">
                <b class="case01" data-textAni>
                    RE100 장기고정계약 요건
                </b>
                <ul data-animate-list="bottomToTop" data-aniDelay="4">
                    <li>
                        <span>설비 용량</span>
                        <b>1MW급 이상</b>
                        <small>*단일 발전소 기준</small>
                    </li>
                    <li>
                        <span>발전소 유형</span>
                        <b>육상형</b>
                        <small>
                            *ESS 연계, 수상형, <br>
                            지붕형 별도 협의
                        </small>
                    </li>
                    <li>
                        <span>계약 기간</span>
                        <b>20년 이상</b>
                        <small>
                            *단기계약의 경우 <br>
                            별도 협의
                        </small>
                    </li>
                    <li>
                        <span>계약 요건</span>
                        <b>
                            RPS<br class="mobile"> 고정가격계약,<br>
                            FIT 미체결<br class="mobile"> 발전소
                        </b>
                    </li>
                </ul>
                <p data-textAni>
                    RPS 장기고정계약 대비 <b>경쟁력 있는 수익 조건</b><br>
                    수의계약으로 보다 <b>간소한 계약 절차</b>
                </p>
            </div>
        </div>

        <div data-scrollPosition="50">
            <div class="condition02Area contentSize-padding">
                <strong class="subTitle" data-textAni>발전소 매입</strong>
                <p class="subTitle2" data-textAni data-aniDelay="2">
                    <b>업계 최고가 수준</b>으로<br class="mobile"> 발전자원을 매입합니다.
                </p>
                <b class="case01" data-textAni data-aniDelay="4">
                    발전소 매입 주요 조건
                </b>
                <ul data-animate-list="bottomToTop" data-aniDelay="8">
                    <li>
                        <span>설비 용량</span>
                        <b>1MW급 이상</b>
                    </li>
                    <li>
                        <span>발전소 유형</span>
                        <b>육상형</b>
                    </li>
                    <li>
                        <span>부지 유형</span>
                        <b>자가 소유, 임대</b>
                    </li>
                    <li>
                        <span>사업자 유형</span>
                        <b>
                            법인 또는 <br>
                            개인사업자
                        </b>
                    </li>
                    <li>
                        <span>사업 개발 단계</span>
                        <b>
                            인허가 및 <br>
                            계통 확보 완료 <br>
                            발전소
                        </b>
                    </li>
                </ul>
            </div>
        </div>

        <div data-scrollPosition="50">
            <div class="textArea contentSize-padding">
                <ul data-animate-list="rightToLeft">
                    <li>다양한 형태의 자산 매입</li>
                    <li>신속한 사업 검토와 자금 투입</li>
                    <li>유연한 대금 지급조건 협의</li>
                </ul>
            </div>
        </div>
    
        <div data-scrollPosition="50">
            <div class="simplifyArea contentSize-padding">
                <p class="subTitle" data-textAni>
                    빠른 지급, 유연한 협의<br>
                    <b class="font-hanwha">테라와트아워</b>라 가능합니다.
                </p>
                <ul data-animate-list="bottomToTop" data-aniDelay="4" data-aniDelay-list="4">
                    <li>
                        <b>글로벌 재생에너지 No.1</b>
                        <p>
                            RE100 참여기업 파이프라인 확보<br>
                            안정적인 발전소 운영 및 성능 보장
                        </p>
                    </li>
                    <li>
                        <b>국내 최대 금융사</b>
                        <p>
                            1500억 규모 펀드 설립<br>
                            장기계약 시 금융 상품 연계 지원
                        </p>
                    </li>
                </ul>
            </div>
        </div>

        <hr class="case01">

        <div class="contentSize-padding" data-scrollPosition="70">
            <p class="inquiryTitle">
                RE100 장기고정계약 & 발전소 매입<br>
                <b class="font-hanwha">테라와트아워</b>에 문의하세요!
            </p>
            <form>
                <fieldset class="content-inquiry">
                    <ul>
                        <li>
                            <label for="company">기업명</label>
                            <div>
                                <input type="text" id="company" name="company" @input="onChange" required>
                            </div>
                        </li>
                        <li>
                            <label for="name">담당자명</label>
                            <div>
                                <input type="text" id="name" name="name" @input="onChange" required>
                            </div>
                        </li>
                        <li>
                            <label for="phonenum">전화번호</label>
                            <div>
                                <input type="text" id="phonenum" name="phonenum" @input="onChange" maxlength="11" data-formet="number" required>
                            </div>
                        </li>
                        <li>
                            <label for="email">이메일</label>
                            <div>
                                <input type="text" id="email" name="email" @input="onChange" required>
                            </div>
                        </li>
                        <li>
                            <label for="">문의내용</label>
                            <div>
                                <input type="radio" id="inquerydetail-contract" name="inquerydetail" value="LONGTERMCONTRACT" @input="onChange" required checked>
                                <label for="inquerydetail-contract">장기고정계약</label>
                                <input type="radio" id="inquerydetail-forSale" name="inquerydetail" value="FORSALE" @input="onChange" required>
                                <label for="inquerydetail-forSale">발전소 매각</label>
                                <input type="radio" id="inquerydetail-etc" name="inquerydetail" value="ETC" @input="onChange" required>
                                <label for="inquerydetail-etc">기타</label>
                            </div>
                        </li>
                        <li>
                            <label for="">발전소 현황</label>
                            <div>
                                <input type="radio" id="plantstatus-beforeCompletion" name="plantstatus" value="BEFORECOMPLETION" @input="onChange" required checked>
                                <label for="plantstatus-beforeCompletion">준공전 발전소</label>
                                <input type="radio" id="plantstatus-inOperation" name="plantstatus" value="INOPERATION" @input="onChange" required>
                                <label for="plantstatus-inOperation">운영중 발전소</label>
                            </div>
                        </li>
                        <li>
                            <label for="plantcapacity">용량</label>
                            <div data-unit="kW">
                                <input type="text" id="plantcapacity" name="plantcapacity" placeholder="발전소 용량을 입력하세요" @input="onChange" data-formet="number">
                            </div>
                        </li>
                        <li>
                            <label for="recweight">REC 가중치</label>
                            <div>
                                <input type="text" id="recweight" name="recweight" placeholder="예시 : 1.0, 1.2, 1.5" @input="onChange" data-formet="number">
                            </div>
                        </li>
                        <li>
                            <label for="content">상세 내용</label>
                            <textarea id="content" name="content" placeholder="기타 문의 및 참고 내용을 작성하세요. (200자 내)" @input="onChange"></textarea>
                        </li>
                    </ul>
                    <inquiry-agree :inputsRequired="inputsRequired" :inputs="inputs"></inquiry-agree>
                    <input type="submit" value="문의하기" class="btn-black" @click.prevent="onSubmit">
                </fieldset>
            </form>
            <modal-alert v-if="isModal" :isModal="isModal" @modalClose="modalClose" :modalText="modalText"/>
        </div>
    </section>
</template>
<script>
import * as api from '../api/api'
import InquiryAgree from '@/components/InquiryAgree.vue';
import ModalAlert from '@/components/modal/ModalAlert.vue';
import { scrollPositionAdd, scrollPositionRemove } from '../css/style.js'

export default {
    name: 'RecruitPage',
    components : {
        InquiryAgree,
        ModalAlert
    },
    data() {
        return {
            topClassName: '[data-subTopAni] .topArea',
            topElement: undefined,
            topContents: undefined,
            titleElement: undefined,
            inputsRequired: {},
            inputs: {},
            isModal: false,
            modalText: {
                title: '',
                description: '',
            }
        }
    },
    methods: {
        init(){
            this.topElement = document.querySelector(this.topClassName);
            this.titleElement = document.querySelector(`${this.topClassName} h2`);
            this.topContents = document.querySelectorAll(`${this.topClassName} > div`);
            // 타이틀 초기화
            this.titleElement.classList.remove('active');
            setTimeout(() => {
                this.titleElement.classList.add('active');
            }, 300);
            // 서브페이지 탑 애니메이션 초기화
            this.topContents.forEach((element)=>{
                element.classList.remove('active');
                element.classList.remove('ani');
            })
            setTimeout(() => {
                this.topContents.forEach((element)=>{
                    element.classList.add('ani');
                })
            }, 300);
            this.topElement.style.setProperty('--bgY', 0);
            this.topContents.forEach((element)=>{
                element.classList.remove('active');
            })
        },
        scrollEvent() {
            const topElement = this.topElement;
            const topContents = this.topContents;
            const headerElement = document.querySelector('header');
            topElement.style.setProperty('--totalContent', topContents.length);
            window.addEventListener('scroll', ()=>{
                const scrollTop = window.scrollY;
                const topElementTop = topElement.offsetTop;
                const topElementHeight = topElement.offsetHeight;
                const headerElementHeight = headerElement.offsetHeight;
                topContents.forEach((_, idx)=>{
                    if(scrollTop > (topElementHeight - window.innerHeight) / topContents.length * idx + headerElementHeight){
                        topContents[idx].classList.add('active');
                    }else{
                        topContents[idx].classList.remove('active');
                    }
                })
                if(scrollTop > topElementTop && scrollTop < topElementHeight + topElementTop){
                    topElement.style.setProperty('--bgY', -((scrollTop - topElementTop) / topElementHeight * 250) + 'px');
                }
            })
        },
        onSubmit(){
            if(!api.isRequired(this.inputsRequired)){
                api.user('inquiry', {...this.inputsRequired, ...this.inputs, consultpurpose: 'RECURUT'})
                    .then((result)=>{
                        if(result.statusCode === '200'){
                            this.modalText['title'] = '문의'
                            this.modalText['description'] = '요청이 완료되었습니다' 
                            this.isModal = true
                            api.dataInit(this.inputsRequired, this.inputs)
                            /* gtag('event', 'contact_us', {
                                'event_name': 'contact_us'
                            }); */
                            this.$gtag.event('click', {
                                event_category: 'inquiry',
                                event_label: 'recruit inquiry',
                                value: 'power_plant_recruitment',
                            })
                        }
                    })
            }
        },
        onChange(e){
            api.onChange(e, this.inputsRequired, this.inputs)
        },
        modalClose(){
            this.isModal = false;
        }
    },
    mounted() {
        this.init();
        this.scrollEvent();
        scrollPositionAdd();
        document.querySelectorAll('input[required]').forEach((element)=>{
            if(element.type === 'radio'){
                this.inputsRequired[element.name] = element.checked;
                return;
            }
            element.required && (this.inputsRequired[element.name] = '');
        })
    },
    beforeUnmount(){
        this.init();
        scrollPositionRemove();
    }
}
</script>
<style>
    .simplifyArea ul[data-animate-list="bottomToTop"]::before{opacity: 0; transform: translateY(100%); transition-property: transform, opacity; transition-duration: var(--aniDuration); transition-timing-function: ease-in-out;}
    .active .simplifyArea ul[data-animate-list="bottomToTop"]::before{opacity: 1; transform: translateY(0%); transition-delay: calc(var(--aniDelay) + var(--aniDelay-basic) + (var(--aniDelay-list) / 2));}
</style>