<template>
    <div class="content-modal" @click="$emit('modalClose')">
        <div class="infoArea" @click.stop>
            <strong>개인정보 취급방침</strong>
            <div>
                <!-- <strong>개인정보취급방침</strong> -->
                <p>
                    '한화 신한 테라와트아워' (이하 '회사')가 운영하는 인터넷 웹사이트(www.hanwhashinhantwh.com)를
                    이용하는   이용자의   개인정보보호에   대하여   규정하고   있습니다.  회사는   이용자의   개인정보를
                    중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률, 개인정보보호지침, 신용 정보의 이용 및
                    보호에 관한 법률,  전자거래기본법,  전자거래소비자보호지침 등 개인정보보호 관련 법규를 준수하고
                    있습니다. 회사는 개인정보처리방침을 통하여 이용자께서 제공하시는 개인정보가 어떠한 용도와 방식으로
                    이용되고   있으며,  개인정보보호를   위해   어떠한   조치가   취해지고   있는지   알려드립니다.  회사의
                    개인정보처리방침은 법률의 개정 및 정부지침의 변경 또는 회사 내부방침의 변경에 따라 바뀔 수 있으므로
                    이용자께서 수시로 확인하여 주시기 바랍니다. (본 방침은 2023년 11월 30일부터 시행됩니다.)
                </p>
                <ol>
                    <li><strong>1. 수집하는 개인정보의 항목</strong>
                        <ol>
                            <li>① 회사는 고객문의, 고객불만접수 등 다양한 인터넷 서비스를 제공하기 위하여 적법하고 공정한 수단에 의하여 회원님의 개인정보를 수집하고 있습니다.
                                <ul>
                                    <li>∎ 수집정보 항목 : 성명, 연락처, 이메일 주소</li>
                                </ul>
                            </li>
                            <li>② 고객님의 기본적 인권 침해의 우려가 있는 민감한 개인정보는 수집하지 않습니다.</li>
                        </ol>
                    </li>
                    <li><strong>2. 개인정보수집방법</strong>
                        <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
                        <ul>
                            <li>∎ 전화, 홈페이지를 통한 상담신청정보</li>
                            <li>∎ 고객문의 게시판을 통한 상담정보</li>
                            
                        </ul>
                    </li>
                    <li><strong>3. 개인정보의 수집 및 이용목적</strong>
                        <p>
                            회사는 이용자의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본조에서 고지한 범위를 넘어
                            이용자의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.
                        </p>
                        <ul>
                            <li>∎ 견적 요청 시, 견적 산출, 자료 전달 및 상담 등을 위해 활용</li>
                            <li>∎ 회원제 서비스 제공, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사 확인, 연령확인, 미성년자 결제 시 법정대리인 동의여부 확인, 불만처리 등 민원처리, 고지사항 전달 등</li>
                            <li>∎ 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산, 콘텐츠 제공, 구매 및 요금 결제, 물품배송 또는 청구지 발송</li>
                            <li>∎ 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산, 콘텐츠 제공, 구매 및 요금 결제, 물품배송 또는 청구지 발송</li>
                            <li>∎ 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</li>
                        </ul>
                    </li>
                    <li><strong>4. 목적 외 사용 및 제3자에 대한 제공</strong>
                        <p>
                            회사는 법령에 근거가 있거나 정부의 관련 지침,  지시 등 예외적인 경우를 제외하고는 이용자의
                            사전동의없이 이용자의 개인정보를 본 개인정보처리방침상의 정보이용목적을 넘어 사용하거나 제 3자에게
                            제공하지 않습니다.
                        </p>
                        <p>
                            이용자가 가입약관을 위배하거나 제3자에게 피해를 주는 등의 부정한 행위 등으로 인하여 법적 조치를
                            취하거나 사법기관의 요청이 있을 경우에는 이용자의 동의없이 관련 기관에 제공할 수 있습니다.
                        </p>
                    </li>
                    <li><strong>5. 개인정보의 보유 및 이용기간</strong>
                        <p>이용자가 제공한 개인정보는 회사가 제공하는 서비스를 받는 동안 회사가 보유, 이용하게 됩니다.</p>
                        <p>회사는 회원이 수집목적을 달성하거나 사업을 폐업하는 경우에는 수집된 개인정보를 지체없이 파기하겠습니다.</p>
                        <ul>
                            <li>∎ 회원 가입 정보의 경우 : 회원탈퇴 요청이 있거나 회원 자격을 상실한 때</li>
                            <li>∎ 사업을 폐업하는 경우</li>
                            <li>∎ 기타 보유 정보 : 정보의 수집 및 이용 목적이 달성된 때</li>
                        </ul>
                        <p>
                            회사는 "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 제 29조 2항에 의거하여 최근 12개월 간
                            서비스 이용내역이 없는 이용자의 개인정보를 보호하기 위하여 다른 이용자의 정보와 분리하여 저장,
                            관리합니다. 분리 보관된 개인정보는 3년간 안전하게 보관 후 파기합니다. 다만, 개인정보의 수집 및
                            이용목적이 달성된 경우에도 상법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관계법령의 규정에
                            의하여 보존하여야 하는 기록은 일정기간 보존 후 파기합니다. 계약 또는 청약철회 등에 관한 기록 : 5년 (
                            전자상거래등에서의 소비자보호에 관한 법률)
                        </p>
                        <ul>
                            <li>∎ 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)</li>
                            <li>∎ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)</li>
                            <li>∎ 운전면허번호 : 5년(주정차 위반 및 도로교통법 위반 등으로 인한 과태료, 범칙금 부과 처리 등)</li>
                        </ul>
                    </li>
                    <li><strong>6. 개인정보의 파기절차 및 방법</strong>
                        <p>이용자의 개인정보는 윈칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</p>
                        <ol>
                            <li>
                                <b>가. 파기절차</b>
                                <p>
                                    이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의
                                    서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정기간 저장된 후 파기됩니다.
                                </p>
                            </li>
                            <li>
                                <b>나. 파기 방법</b>
                                <p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
                                <p>전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
                            </li>
                        </ol>
                    </li>
                    <li><strong>7. 수집한 개인정보의 위탁</strong>
                        <p>회사는 고객의 개인정보 취급업무를 위탁하지 않고 있습니다.</p>
                    </li>
                    <li><strong>8. 이용자 및 법정대리인의 권리ᆞ의무 및 행사방법</strong>
                        <ol>
                            <li>① 이용자는 회사에 대해 언제든지 다음 각호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
                                <ul>
                                    <li>∎ 개인정보 열람요구</li>
                                    <li>∎ 오류 등이 있을 경우 정정요구</li>
                                    <li>∎ 삭제요구</li>
                                    <li>∎ 처리정지 요구</li>
                                </ul>
                            </li>
                            <li>② 제1항에 따른 권리행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.</li>
                            <li>③ 이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</li>
                            <li>④ 만 14세 미만 아동의 경우 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은자 등 대리인을 통하여 하실 수 있습니다. 이경우 법정대리인은 이용자의 모든 권리를 가집니다.</li>
                            <li>⑤ 이용자는 정보통신망법, 개인정보보호법 등 관계 법령을 위반하여 회사가 처리하고 있는 이용자 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.</li>
                        </ol>
                    </li>
                    <li><strong>9. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</strong>
                        <p>회사는 고객님의 정보를 찾아내고 저장하는 별도 '쿠키(Cookie)'를 사용하고 있지 않으며 별도의 로그분석 및 접속IP분석 등의 자동수집 장치를 사용하고 있지 않습니다.</p>
                    </li>
                    <li><strong>10. 개인정보 보호책임자</strong>
                        <p>개인정보 보호 책임자 성명 : 차성종</p>
                        <ul>
                            <li>∎ 직위 : CFO</li>
                            <li>∎ 전화번호 : 02-318-2309</li>
                            <li>∎ 이메일 : cha3820@naver.com</li>
                        </ul>
                        <p>이용자께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
                        <p>또한, 개인정보의 열람, 정정, 삭제, 처리 정지 등에 대한 정보주체자의 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</p>
                        <p>또한, 기타 개인정보침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.</p>
                        <ul>
                            <li>- 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</li>
                            <li>- 개인정보분쟁조정위원회 (www.kopico.go.kr / 02-1833-6972)</li>
                            <li>- 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</li>
                            <li>- 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)</li>
                        </ul>
                    </li>
                    <li><strong>11. 개인정보처리방침의 적용제외</strong>
                        <p>
                            회사는 이용자에게 홈페이지를 통하여 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다.
                            이 경우 회사는 외부사이트 및 자료에 대하여 통제권이 없을 뿐만 아니라 이들이 개인정보를 수집하는
                            행위에 대하여 회사의  '개인정보처리방침'이 적용되지 않습니다.  따라서,  링크를 클릭하여 타사이트의
                            페이지로 이동할 경우에는 새로 방문한 사이트의 개인정보처리방침을 반드시 확인하시기 바랍니다.
                        </p>
                        <b>고객센터</b>
                        <ul>
                            <li>∎ 이메일 : sales@twh.co.kr</li>
                            <li>∎ 전화번호 : 02-318-2309</li>
                        </ul>
                    </li>
                    <li><strong>12. 개인정보 관련 의견수렴 및 불만처리</strong>
                        <p>회사는 정보주체의 의견을 소중하게 생각하며, 정보주체는 의문사항에 대해 언제나 성실한 답변을 받을 권리가 있습니다.</p>
                    </li>
                    <li><strong>13. 고지의 의무</strong>
                        <p>
                            현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의 ‘공지사항’을
                            통해 고지할 것입니다. 다만, 개인정보 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이
                            있을 경우에는 최소 30일 전에 고지합니다.
                        </p>
                        <ul>
                            <li>∎ 공고일자 : 2023년 11월 30일</li>
                            <li>∎ 시행일자 : 2023년 11월 30일</li>
                        </ul>
                    </li>
                </ol>
            </div>
            <button class="close" @click.prevent="$emit('modalClose')">모달 닫기</button>

            
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalInfo',
    props: {
        isModal: Boolean,
        modalText: Object
    },
    data(){
        return{
            test: ''
        }
    }
}
</script>
<style>
    
</style>